<template>
  <div class="headline">首页</div>
  <div class="yueFen">
    <el-date-picker
      v-model="month2"
      @change="timing"
      type="month"
      placeholder="选择查询月份"
    >
    </el-date-picker>
    <el-button style="margin-left: 40px" type="primary" @click="inquire"
      >查询</el-button
    >
    <el-button style="margin-left: 40px" type="primary" plain @click="reset"
      >重置</el-button
    >
  </div>
  <div>
    <div class="list_top">
      <span class="zhuanS"></span
      ><span class="yuefeng">{{ plannedTime }}月份计划</span>
    </div>
    <div class="project">
      <div
        class="plate"
        :class="bagImg[index]"
        v-for="(item, index) in planList"
        :key="item.id"
        @click="
          () => {
            skipPlan(item, 1);
          }
        "
      >
        <div class="title_wb">{{ item.title }}</div>
        <div class="vaule_zhi">{{ item.value }}</div>
      </div>
    </div>
  </div>
  <div style="margin-top: 150px">
    <div class="list_top">
      <span class="zhuanS"></span
      ><span class="yuefeng">{{ month }}月份总结</span>
    </div>
    <div class="project">
      <div
        class="plate"
        :class="bagImg[index]"
        v-for="(item, index) in performanceList"
        :key="item.id"
        @click="
          () => {
            skipPlan(item, 2);
          }
        "
      >
        <div class="title_wb">{{ item.title }}</div>
        <div class="vaule_zhi">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { homePage } from '@/api/user'
export default {
  name: 'home',
  props: {
    onTab: Function
  },
  setup (props) {
    const bagImg = [
      'uncommitted',
      'submitted',
      'underReview',
      'alreadyPassed',
      'submitted'
    ]
    const router = useRouter()
    const plannedTime = ref('')
    const performanceList = ref([])
    const planList = ref([])
    const month = ref('')
    const month2 = ref('')
    const time1 = ref('')
    const time2 = ref('')

    const getList = () => {
      homePage({
        month: month2.value
      }).then((res) => {
        if (res.code === 200) {
          const lists = { ...res.data.plan }
          const listSion = res.data.conclusion
          if (lists) {
            const list = res.data.plan.planMonth
            time1.value = list
            plannedTime.value = list.split('-')[1]
            const arr = []
            for (const i in lists) {
              if (['1', '2', '3', '4', '5', '6', '7'].includes(i)) {
                arr.push({
                  id: i,
                  value: lists[i],
                  title: screening(i)
                })
              }
            }
            console.log(arr)
            const a = arr.reduce((total, item) => {
              console.log(total)
              if (item.id === '2' || item.id === '3' || item.id === '4') {
                total += parseInt(item.value)
              }
              return total
            }, 0)
            arr.splice(4)
            arr.push({
              id: '-1',
              value: a,
              title: '已提交 （人）'
            })

            planList.value = arr
          }
          const performance = res.data.conclusion
          if (performance) {
            time2.value = performance.conclusionPlan
            month.value = performance.conclusionPlan.split('-')[1]
            const arr1 = []
            for (const a in listSion) {
              if (['1', '2', '3', '4', '5', '6', '7'].includes(a)) {
                arr1.push({
                  id: a,
                  value: listSion[a],
                  title: screening2(a)
                })
              }
            }
            const b = arr1.reduce((total, item) => {
              if (['1', '2', '3', '4', '5'].includes(item.id)) {
                total = 0
              } else {
                total += parseInt(item.value)
              }
              return total
            })
            const k = arr1.reduce((total, item) => {
              if (['6', '7'].includes(item.id)) {
                return total
              } else {
                total += parseInt(item.value)
              }
              return total
            }, 0)
            console.log(k)
            arr1[3].value = k
            console.log(b)

            arr1[4].value = b
            const obj = arr1.splice(3)
            obj[0].id = '1,2,3,4,5'
            obj[1].id = '6,7'
            performanceList.value = obj
          }
        }
      })
    }
    // 查询
    const inquire = () => {
      getList()
    }
    // 重置
    const reset = () => {
      month2.value = ''
      getList()
    }
    const timing = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        const time = y + '-' + m
        month2.value = time
      }
    }
    // 点击跳转
    const skipPlan = (item, e) => {
      console.log(item)
      let t = ''
      if (e === 1) {
        t = time1.value
      } else {
        t = time2.value
      }
      let codeId = ''
      if (item.id === '-1') {
        codeId = '2,3,4'
      } else {
        codeId = item.id
      }
      const data = {
        id: codeId,
        t
      }
      sessionStorage.setItem('item', JSON.stringify(data))
      props.onTab('3')
    }
    const screening = (e) => {
      let key = ''
      switch (e) {
        case '1':
          key = '未提交（人）'
          break
        case '2':
          key = '待审核（人）'
          break
        case '3':
          key = '已拒绝（人）'
          break
        case '4':
          key = '已通过（人）'
          break
        default:
          key = '未定义'
      }
      return key
    }
    const screening2 = (e) => {
      let f = ''
      switch (e) {
        case '4':
          f = '未提交（人）'
          break
        case '5':
          f = '已提交（人）'
          break
        case '6':
          f = '审批中（人）'
          break
        case '7':
          f = '已通过（人）'
          break
        default:
          f = '未定义'
      }
      return f
    }
    onMounted(() => {
      getList()
    })
    return {
      performanceList,
      bagImg,
      getList,
      plannedTime,
      screening,
      month,
      planList,
      skipPlan,
      router,
      screening2,
      inquire,
      time1,
      time2,
      month2,
      timing,
      reset
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.headline {
  width: 100%;
  height: 80px;
  line-height: 80px;
  padding-left: 200px;
}
.yueFen {
  margin-left: 245px;
  margin-bottom: 20px;
}
.list_top {
  width: 25%;
  text-align: center;
  margin-bottom: 20px;
  .zhuanS {
    width: 4px;
    height: 20px;
    line-height: 38px;
    opacity: 1;
    background: #1890ff;
    border-radius: 3px;
    display: inline-block;
  }
  .yuefeng {
    width: 87px;
    height: 33px;
    opacity: 1;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    margin-left: 10px;
  }
}
.project {
  display: flex;
  justify-content: space-evenly;
  .plate {
    width: 273px;
    height: 108px;
    text-align: center;
    .title_wb {
      font-size: 16px;
      margin-top: 20px;
      color: #fff;
    }
    .vaule_zhi {
      height: 36px;
      opacity: 1;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      line-height: 37px;
      margin-top: 10px;
    }
  }
  .submitted {
    background-image: url("../../../assets/images/submitted.svg");
  }
  .uncommitted {
    background-image: url("../../../assets/images/uncommitted.svg");
  }
  .underReview {
    background-image: url("../../../assets/images/underReview.svg");
  }
  .alreadyPassed {
    background-image: url("../../../assets/images/alreadyPassed.svg");
  }
}
</style>
