<template>
  <div class="headquarteHing">
    <div class="headline">审批管理</div>
      <div class="search">
      <div class="input_sh">
        <el-input
          placeholder="请输入姓名"
          v-model="name"
          clearable>
        </el-input>
      </div>
      <div class="input_sh">
        <el-input
          placeholder="请输入部门名称"
          v-model="departmentName"
          clearable>
        </el-input>
      </div>
      <div class="button_cx">
        <el-button type="primary" @click="inquire">查询</el-button>
      </div>
      <div class="button_cz">
        <el-button type="primary" @click="reset" plain>重置</el-button>
      </div>
    </div>

    <div class="tabe_class">
      <el-table
        :data="tableData"
        style="width: 96%"
        max-height="650"
        :default-sort = "{prop: 'date', order: 'descending'}"
        @select="selectClick"
      >
        <!-- <el-table-column
            type="selection"
            width="55">
        </el-table-column> -->
        <el-table-column
          prop="id"
          label="序号"
          width = "60">
          <template #default="data">
              <span>{{data.$index + 1 +cont}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="姓名"
           width = "100">
        </el-table-column>
        <el-table-column
          prop="departmentName"
          label="所属部门"
           width = "1340"
        >
        </el-table-column>
        <el-table-column
          label="操作"
           fixed="right"
        >
          <template #default="data">
            <el-button
              size="mini"
              type="primary"
              @click="() => {deleteAuthId(data)}">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    <div class="paginationY">
      <el-pagination
        :page-size="10"
        :pager-count="7"
        background
        layout="prev, pager, next"
        @current-change="currentChanges"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-dialog
          title="提示"
          v-model="dialogVisible"
          width="30%"
          :before-close="handleClose">
          <span>确定删除人员的审批流程？</span>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogVisible = false" style="margin-right:30px">取 消</el-button>
              <el-button type="primary" @click="remove">确 定</el-button>
            </span>
          </template>
      </el-dialog>
    </div>

  </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import { managementGetList, managementDelete } from '@/api/user'
import { ElMessage } from 'element-plus'
export default {
  name: 'considerSituation',
  setup () {
    const tableData = ref([])
    const name = ref('')
    const departmentName = ref('')
    const currentChange = ref(1)
    const dialogVisible = ref(false)
    const cont = ref(0)
    const total = ref(0)
    const id = ref('')
    // 获取页面数据
    const getStaffList = () => {
      managementGetList({
        current: currentChange.value,
        size: 10,
        userName: name.value,
        departmentName: departmentName.value
      }).then((res) => {
        console.log(res.data)
        if (res.code === 200) {
          tableData.value = res.data.records
          total.value = res.data.total
          currentChange.value = res.data.current
          cont.value = (res.data.current - 1) * res.data.size
        }
      })
    }
    // 查询
    const inquire = () => {
      getStaffList()
    }
    // 重置
    const reset = () => {
      currentChange.value = 1
      name.value = ''
      departmentName.value = ''
      getStaffList()
    }
    // 下一页
    const currentChanges = (e) => {
      if (e) {
        currentChange.value = e
        getStaffList()
      }
    }
    onMounted(() => {
      getStaffList()
    })
    const remove = () => {
      console.log(id.value)
      managementDelete({
        ids: id.value
      }).then((res) => {
        if (res.code === 200) {
          ElMessage.success('删除成功!')
          getStaffList()
          dialogVisible.value = false
        } else {
          ElMessage.error(res.msg)
          dialogVisible.value = false
        }
      })
    }
    // 勾选删除
    const selectClick = (e) => {
      console.log(e)
    }
    const deleteAuthId = (data) => {
      console.log(data)
      id.value = data.row.id
      dialogVisible.value = true
    }
    return {
      id,
      name,
      departmentName,
      tableData,
      getStaffList,
      remove,
      dialogVisible,
      deleteAuthId,
      total,
      currentChange,
      inquire,
      reset,
      currentChanges,
      selectClick,
      cont
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.headquarteHing {
  .headline {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding-left: 200px;
  }
  .search {
    display: flex;

    .select_sh {
      width: 224px;
      margin-left: 40px;
    }
   .input_sh{
    margin-left: 42px;
   }
    .button_cx {
      margin-right: 80px;
      margin-left: 30px;
    }
  }
  .el-button--small {
    padding: 4px 4px !important;
  }
  .el-drawer__open .el-drawer.rtl {
    width: 28% !important;
  }
  .el-input--small .el-input__inner {
    width: 100%;
    height: 40px;
    line-height: 32px;
  }
  .el-table--small td,
  .el-table--small th {
    padding: 10px 0;
  }
  .el-switch.is-disabled {
    opacity: 0.6;
    margin-left: 17px;
  }
  .el-drawer__open .el-drawer.rtl {
    width: 32% !important;
  }
  .el-button {
    width: 60px;
    font-size: 14px !important;
    border-right: 1px solid #d3d8dd;
  }

  .el-drawer__header {
    margin-bottom: 16px !important;
  }
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
    margin-right: 15px;
    font-size: 14px;
  }
  .el-table th {
    background-color: #e6ebf0;
    color: #000;
  }
  .el-pagination {
    margin-right: 62px;
    margin-top: 50px;
  }
  .el-switch {
    margin-left: 20px;
  }
  .el-table th,
  .el-table tr {
    font-size: 16px;
    padding: 15px 0;
  }
  .tabe_class {
    margin-left: 195px;
    margin-top: 20px;
  }
  .dian {
    margin-right: 8px;
    content: " ";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 9px;
  }
  .green {
    background-color: #52c41a;
  }
  .red {
    background-color: red;
  }
  .inline {
    width: 60px;
    display: inline-block;
  }
  .paginationY {
    width: 100%;
    text-align: center;
    margin-right: 50px;
  }
}
</style>
