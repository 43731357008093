<template>
  <div class="consideration">
    <div class="input_but">
      <div class="danYi">
        <div class="headlineTime">
          <span class="yuefei">月份：</span>
        </div>
        <el-date-picker
          v-model="value5"
          type="month"
          placeholder="选择日期时间">
        </el-date-picker>
      </div>
      <div class="danYi">
        <span class="yuefei">填写开始时间：</span>
        <el-date-picker
          v-model="value1"
          type="datetime"
          placeholder="请选择开始时间">
        </el-date-picker>
      </div>
      <div class="danYi">
        <span class="yuefei">填写结束时间：</span>
        <el-date-picker
          v-model="value2"
          type="datetime"
          placeholder="请选择结束时间">
        </el-date-picker>
      </div>
      <div class="danYi">
        <span class="yuefei">评分开始时间：</span>
        <el-date-picker
          v-model="value3"
          type="datetime"
          placeholder="请选择评分开始时间">
        </el-date-picker>
      </div>
      <div class="danYi">
        <span class="yuefei">评分结束时间：</span>
        <el-date-picker
          v-model="value4"
          type="datetime"
          placeholder="请选择评分结束时间">
        </el-date-picker>
      </div>
    </div>
    <div class="anniu">
      <div class="button_cx">
        <el-button type="primary" plain @click="colse">取消</el-button>
      </div>
      <div class="button_cz">
        <el-button type="primary" @click="submit" >确认</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { timeList, updateTime } from '@/api/user'
import { ElLoading, ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
export default {
  name: 'startTimesDrawer',
  props: {
    setTime: {
      id: String,
      fillEndTime: String,
      fillStartTime: String,
      month: String,
      reviewEndTime: String,
      reviewStartTime: String
    },
    handleClose: Function
  },
  setup (props) {
    const value1 = ref('')
    const value2 = ref('')
    const value3 = ref('')
    const value4 = ref('')
    const value5 = ref('')
    // 获取页面数据
    const getStaffList = (data) => {
      value1.value = data.fillStartTime
      value2.value = data.fillEndTime
      value3.value = data.reviewStartTime
      value4.value = data.reviewEndTime
      value5.value = data.month
    }
    // 提交时间
    const submit = () => {
      if (props.setTime.id) {
        if (value1.value && value2.value && value3.value && value4.value) {
          updateTime({
            id: props.setTime.id,
            fillStartTime: timing(value1.value) ?? '',
            fillEndTime: timing(value2.value) ?? '',
            reviewStartTime: timing(value3.value) ?? '',
            reviewEndTime: timing(value4.value) ?? ''
          }).then(res => {
            console.log(res.data)
            if (res.code === 200) {
              ElMessage.success('时间设置成功')
              props.handleClose()
            }
          })
        } else {
          return ElMessage.error('时间必须填写完整')
        }
      }
    }
    // 时间处理
    const timing = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let M = date.getMinutes()
        M = M < 10 ? ('0' + M) : M
        let s = date.getSeconds()
        s = s < 10 ? ('0' + s) : s
        const time = y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s
        return time
      }
    }
    const colse = () => {
      props.handleClose()
    }
    onMounted(() => {
      console.log(props)
      if (props.setTime.month) {
        getStaffList(props.setTime)
      }
    })
    return {
      value1,
      value2,
      value3,
      value4,
      value5,
      getStaffList,
      submit,
      timing,
      colse
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less">
.consideration{
  .input_but{
    width: 100%;
    text-align: center;
    .danYi{
      margin-bottom: 25px;
      .headlineTime{
        width: 109px;
        text-align: right;
        display: inline-block;
      }
      .yuefei{
        width: 148px;
        height: 22px;
        opacity: 1;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: rgba(0,0,0,0.65);
        line-height: 22px;
      }
    }
  }
  .anniu{
    display: flex;
    justify-content: center;
    margin-top: 350px;
    .button_cz{
      margin-left: 15px;
    }
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 321px;
    height: 40px;
    opacity: 1;
    background: rgba(0,0,0,0.04);
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 5px;
  }
}
</style>
