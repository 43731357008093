<template>
  <div class="considerationHs">
        <div class="selectHs">
            <el-select v-model="value" filterable  @change="optionsData" placeholder="请选择会审人员">
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.userName"
                    :value="item.userid + ','  +item.userName">
               </el-option>
           </el-select>
        </div>
         <div class="selectHs">
            <el-select v-model="value2"   placeholder="请选择会审人员类型">
                <el-option
                    v-for="item in staffType"
                    :key="item.id"
                    :label="item.type"
                    :value="item.id">
               </el-option>
           </el-select>
        </div>
        <div class="buttonStaff">
                <el-button @click="close">取消</el-button>
               <el-button type="primary" style='margin-left:50px' @click="submit">提交</el-button>
        </div>
  </div>
</template>
<script>
import { onMounted, ref, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import { userGetList, addAuth } from '@/api/user'
import { useStore } from '@/vuex/store'
export default {
  name: 'addJointHearing',
  props: {
    handleClose: Function
  },
  setup (props) {
    const options = ref([])
    const staffType = ref([{ id: 1, type: '走会审人' }, { id: 2, type: '会审人员' }, { id: 3, type: '会审的复审人' }, { id: 4, type: '抄送人' }, { id: 5, type: '不在走会审，显示按钮' }])
    const value = ref('')
    const id = ref('')
    const name = ref('')
    const value2 = ref('')
    const getStaff = () => {
      userGetList({}).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          options.value = res.data
        }
      })
    }
    const optionsData = (e) => {
      console.log(e)
      if (e) {
        value.value = e
        id.value = e.split(',')[0]
        name.value = e.split(',')[1]
      }
      console.log(e.split(',')[0], e.split(',')[1])
    }
    const submit = () => {
      console.log(id.value)
      if (!id.value) {
        return ElMessage.error('请选择新增人员!')
      }
      if (!value2.value) {
        return ElMessage.error('请选择新增类型!')
      }
      addAuth({
        userName: name.value,
        userId: id.value,
        personalType: value2.value
      }).then(res => {
        if (res.code === 200) {
          ElMessage.success('添加成功!')
          name.value = ''
          id.value = ''
          value2.value = ''
          value.value = ''
          props.handleClose()
        }
      })
    }
    const close = () => {
      name.value = ''
      id.value = ''
      value2.value = ''
      value.value = ''
      props.handleClose()
    }
    onMounted(() => {

    })
    watchEffect(() => {
      console.log(props)
      getStaff()
    })
    return {
      getStaff,
      options,
      value,
      value2,
      staffType,
      submit,
      optionsData,
      name,
      id,
      close
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less">
.considerationHs{
 .selectHs{
     margin-left: 30px;
     margin-top: 40px;
 }
 .buttonStaff{
     display: flex;
    justify-content: center;
    margin-top: 100px;
 }
}
</style>
