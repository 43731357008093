//审批列表
<template>
  <el-col :span="24">
    <el-menu
      :default-active="collapseIndex"
      class="el-menu-vertical-demo"
      @select="pitchOn"
    >
      <el-menu-item index="1">
        <i class="el-icon-s-home"></i>
        <template #title>首页</template>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-user"></i>
        <template #title>员工管理</template>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-setting"></i>
        <template #title>月绩效管理</template>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-date"></i>
        <template #title>起始时间设置</template>
      </el-menu-item>
      <el-menu-item index="5">
        <i class="el-icon-notebook-2"></i>
        <template #title>月绩效汇总表</template>
      </el-menu-item>
       <el-menu-item index="6">
        <i class="el-icon-notebook-2"></i>
        <template #title>会审人员管理</template>
      </el-menu-item>
        <el-menu-item index="7">
        <i class="el-icon-notebook-2"></i>
        <template #title>审批管理</template>
      </el-menu-item>
    </el-menu>
  </el-col>
  <div class="right_class"  v-if="path === 'components/home'">
    <home :onTab="pitchOn" />
  </div>
  <div class="right_class" v-if="path === 'components/monthlyPerformance'">
    <monthlyPerformance/>
  </div>
  <div class="right_class" v-if="path === 'components/staffManagement'">
    <staffManagement/>
  </div>
  <div class="right_class" v-if="path === 'components/startTimeSetting'">
    <startTimeSetting/>
  </div>
  <div class="right_class" v-if="path === 'components/summarySheet'">
    <summarySheet/>
  </div>
  <div class="right_class" v-if="path === 'components/jointHearing'">
    <jointHearing/>
  </div>
  <div class="right_class" v-if="path === 'components/considerSituation'">
    <considerSituation/>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import home from './components/home'
import staffManagement from './components/staffManagement'
import monthlyPerformance from './components/monthlyPerformance'
import startTimeSetting from './components/startTimeSetting'
import summarySheet from './components/summarySheet'
import jointHearing from './components/jointHearing'
import considerSituation from './components/considerSituation'
import { selectFlowList } from '@/api/user'
import { ElLoading } from 'element-plus'
import { useStore } from '@/vuex/store'
export default {
  name: 'BackStageManagement',
  setup () {
    const path = ref('components/home')
    const collapseIndex = ref(1)
    const pitchOn = (index) => {
      collapseIndex.value = index
      console.log(index)
      if (index === '1') {
        path.value = 'components/home'
      }
      if (index === '2') {
        path.value = 'components/monthlyPerformance'
      }
      if (index === '3') {
        path.value = 'components/staffManagement'
      }
      if (index === '4') {
        path.value = 'components/startTimeSetting'
      }
      if (index === '5') {
        path.value = 'components/summarySheet'
      }
      if (index === '6') {
        path.value = 'components/jointHearing'
      }
      if (index === '7') {
        path.value = 'components/considerSituation'
      }
    }
    return {
      pitchOn,
      path,
      collapseIndex
    }
  },
  components: {
    home,
    staffManagement,
    monthlyPerformance,
    startTimeSetting,
    summarySheet,
    jointHearing,
    considerSituation
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-col-24{
  height: 100%;
  .el-menu{
    height: 100%;
  }
}
.el-menu{
  height: 100%;
}
</style>
