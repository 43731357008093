<template>
  <div class="headquarterTime">
    <div class="headline">起始时间设置</div>
    <div class="search">
      <div class="block" style="margin-left: 41px">
        <el-date-picker
          v-model="selectionTime"
          type="month"
          @change="timing"
          placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="button_cx">
        <el-button type="primary" @click="inquire">查询</el-button>
      </div>
      <div class="button_cz">
        <el-button type="primary" @click="reset" plain>重置</el-button>
      </div>
    </div>
    <div class="tabe_class">
      <el-table
        :data="tableData"
        style="width: 96%"
        :default-sort = "{prop: 'date', order: 'descending'}"
      >
        <el-table-column
          prop="id"
          label="序号"
          style="width:150px">
        </el-table-column>
        <el-table-column
          prop="month"
          label="月份"
          style="width:210px">
        </el-table-column>
        <el-table-column
          prop="fillStartTime"
          label="填写开始时间"
          style="width:210px"
        >
        </el-table-column>
        <el-table-column
          prop="fillEndTime"
          label="填写结束时间"
          style="width:210px"
        >
        </el-table-column>
        <el-table-column
          prop="reviewStartTime"
          label="评分开始时间"
          sortable
          style="width:210px"
        >
        </el-table-column>
        <el-table-column
          prop="reviewEndTime"
          label="评分结束时间"
          sortable
          style="width:210px"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          style="width:150px"
        >
          <template #default="data">
            <el-button
              size="mini"
              type="primary"
              @click="() => {compile(data)}">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationY">
      <el-pagination
        :page-size="10"
        :pager-count="7"
        background
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="total">
      </el-pagination>
    </div>
    <div>
      <el-drawer
        title="编辑"
        v-model="drawer"
        :before-close="handleClose"
        destroy-on-close>
        <startTimesDrawer :setTime="setTime" :handleClose="handleClose" />
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import startTimesDrawer from '../drawer/startTimesDrawer'
import { useRouter } from 'vue-router'
import { timeList } from '@/api/user'
import { ElLoading } from 'element-plus'
import { useStore } from '@/vuex/store'
export default {
  name: 'startTimeSetting',
  setup () {
    const drawer = ref(false)
    const selectionTime = ref('')
    const total = ref('')
    const page = ref(1)
    const month = ref('')
    const setTime = ref({
      id: '',
      fillEndTime: '',
      fillStartTime: '',
      month: '',
      reviewEndTime: '',
      reviewStartTime: ''
    })
    const tableData = ref([])
    // 获取页面数据
    const getStaffList = () => {
      console.log(page.value)
      timeList({
        month: selectionTime.value
      }).then(res => {
        console.log(res.data)
        if (res.code === 200) {
          total.value = res.data.totalCount
          tableData.value = res.data.list
        }
      })
    }
    // 查询
    const inquire = () => {
      getStaffList()
    }
    // 重置
    const reset = () => {
      selectionTime.value = ''
      getStaffList()
    }
    const handleClose = () => {
      getStaffList()
      drawer.value = false
    }
    // 分页
    const currentChange = (e) => {
      console.log(e)
    }
    const compile = (data) => {
      console.log(data)
      console.log(setTime.value)
      if (data) {
        setTime.value.month = data.row.month
        setTime.value.fillEndTime = data.row.fillEndTime
        setTime.value.fillStartTime = data.row.fillStartTime
        setTime.value.reviewEndTime = data.row.reviewEndTime
        setTime.value.reviewStartTime = data.row.reviewStartTime
        setTime.value.id = data.row.id
        drawer.value = true
      }
      console.log(setTime.value)
    }
    const timing = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        const time = y + '-' + m
        selectionTime.value = time
      }
    }
    onMounted(() => {
      getStaffList()
    })
    return {
      timing,
      tableData,
      compile,
      drawer,
      total,
      selectionTime,
      getStaffList,
      inquire,
      currentChange,
      month,
      setTime,
      handleClose,
      reset
    }
  },
  components: {
    startTimesDrawer
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.headquarterTime{
  .headline {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding-left: 200px;
  }
  .search{
    display: flex;
    .select_sh{
      width: 224px;
      margin-left: 40px;
    }
    .input_sh{
      width: 224px;
      margin-left: 12px;
    }
    .input3{
      width: 224px;
      margin-left: 23px;
    }
    .button_cx{
      margin-left: 30px;
    }
    .button_cz{
      margin-left: 10px;
    }
  }
.el-button--small{
    padding: 4px 4px !important;
  }
  .el-drawer__open .el-drawer.rtl{
    width: 28% !important;
  }
  .el-input--small .el-input__inner {
    width: 100%;
    height: 40px;
    line-height: 32px;
  }
  .el-table--small td, .el-table--small th {
    padding: 18px 0;
  }
  .el-switch.is-disabled {
    opacity: .6;
    margin-left: 17px;
  }
  .el-drawer__open .el-drawer.rtl{
    width: 32% !important;
  }
  .el-button {
    width: 60px;
    font-size: 14px !important;
    border-right: 1px solid #d3d8dd;
  }

  .el-drawer__header{
    margin-bottom: 16px !important;
  }
  .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
    margin-left: 0px ;
    margin-right: 15px;
    font-size: 14px;
  }
  .el-table th {
    background-color: #e6ebf0;
    color: #000;
  }
  .el-pagination{
    margin-right: 62px;
    margin-top: 50px;
  }
  .el-switch{
    margin-left: 20px;
  }
  .el-table th, .el-table tr{
    font-size: 16px;
    padding: 15px 0;
  }
  .tabe_class{
    margin-left: 195px;
    margin-top: 20px;
  }
  .dian{
    margin-right: 8px;
    content: " ";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 9px;
  }
  .green{
    background-color: #52C41A;
  }
  .red{
    background-color: red;
  }
  .inline{
    width: 60px;
    display: inline-block;
  }
  .paginationY{
    width: 100%;
    text-align: center;
    margin-right: 50px;
  }
}
</style>
