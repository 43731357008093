<template>
  <div class="headquarterHs">
    <div class="headline">会审人员管理</div>
    <div class="search">
      <div class="button_cx">
        <el-button type="primary" @click="append">新增</el-button>
      </div>
    </div>
    <div class="tabe_class">
      <el-table
        :data="tableData"
        style="width: 96%"
        max-height="650"
        :default-sort = "{prop: 'date', order: 'descending'}"
      >
        <el-table-column
          prop="id"
          label="序号"
          width = "60">
        </el-table-column>
        <el-table-column
          prop="userName"
          label="姓名"
           width = "100">
        </el-table-column>
        <el-table-column
          prop="personalType"
          label="人员类型"
          width = "300">
          <template #default="scope">
             <span>{{screening(scope.row.personalType)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
           width = "1040"
        >
        </el-table-column>
        <el-table-column
          label="操作"
           fixed="right"
        >
          <template #default="data">
            <el-button
              size="mini"
              type="primary"
              @click="() => {deleteAuthId(data)}">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog
          title="提示"
          v-model="dialogVisible"
          width="30%"
          :before-close="handleClose">
          <span>确定删除审核人员？</span>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogVisible = false" style="margin-right:30px">取 消</el-button>
              <el-button type="primary" @click="remove">确 定</el-button>
            </span>
          </template>
</el-dialog>
      <el-drawer
        title="新增"
        v-model="drawer"
        :before-close="handleClose"
        destroy-on-close>
        <addJointHearing :handleClose="handleClose"/>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import addJointHearing from '../drawer/addJointHearing'
import { useRouter } from 'vue-router'
import { authList, deleteAuth } from '@/api/user'
import { ElLoading, ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
export default {
  name: 'jointHearing',
  setup () {
    const drawer = ref(false)
    const tableData = ref([])
    const dialogVisible = ref(false)
    const id = ref('')
    // 获取页面数据
    const getStaffList = () => {
      authList({}).then(res => {
        console.log(res.data)
        if (res.code === 200) {
          tableData.value = res.data
        }
      })
    }
    const screening = (e) => {
      let key = ''
      switch (e) {
        case '1':
          key = '走会审人员'
          break
        case '2':
          key = '会审人员'
          break
        case '3':
          key = '会审复审人'
          break
        case '4':
          key = '抄送人'
          break
        case '5':
          key = '不在走会审，显示按钮'
          break
        default:
          key = '未定义'
      }
      return key
    }
    const append = () => {
      drawer.value = true
    }
    const handleClose = () => {
      getStaffList()
      drawer.value = false
    }

    onMounted(() => {
      getStaffList()
    })
    const remove = () => {
      deleteAuth({
        id: id.value
      }).then(res => {
        if (res.code === 200) {
          ElMessage.success('删除成功!')
          getStaffList()
          dialogVisible.value = false
        } else {
          ElMessage.error(res.msg)
          dialogVisible.value = false
        }
      })
    }
    const deleteAuthId = (data) => {
      dialogVisible.value = true
      id.value = data.row.id
    }
    return {
      id,
      tableData,
      drawer,
      append,
      getStaffList,
      screening,
      remove,
      dialogVisible,
      deleteAuthId,
      handleClose
    }
  },
  components: {
    addJointHearing
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.headquarterHs{
  .headline {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding-left: 200px;
  }
  .search{
    display: flex;
    justify-content: flex-end;
    .select_sh{
      width: 224px;
      margin-left: 40px;
    }

    .button_cx{
      margin-right: 80px;
    }
  }
.el-button--small{
    padding: 4px 4px !important;
  }
  .el-drawer__open .el-drawer.rtl{
    width: 28% !important;
  }
  .el-input--small .el-input__inner {
    width: 100%;
    height: 40px;
    line-height: 32px;
  }
  .el-table--small td, .el-table--small th {
    padding: 10px 0;
  }
  .el-switch.is-disabled {
    opacity: .6;
    margin-left: 17px;
  }
  .el-drawer__open .el-drawer.rtl{
    width: 32% !important;
  }
  .el-button {
    width: 60px;
    font-size: 14px !important;
    border-right: 1px solid #d3d8dd;
  }

  .el-drawer__header{
    margin-bottom: 16px !important;
  }
  .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
    margin-left: 0px ;
    margin-right: 15px;
    font-size: 14px;
  }
  .el-table th {
    background-color: #e6ebf0;
    color: #000;
  }
  .el-pagination{
    margin-right: 62px;
    margin-top: 50px;
  }
  .el-switch{
    margin-left: 20px;
  }
  .el-table th, .el-table tr{
    font-size: 16px;
    padding: 15px 0;
  }
  .tabe_class{
    margin-left: 195px;
    margin-top: 20px;
  }
  .dian{
    margin-right: 8px;
    content: " ";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 9px;
  }
  .green{
    background-color: #52C41A;
  }
  .red{
    background-color: red;
  }
  .inline{
    width: 60px;
    display: inline-block;
  }
  .paginationY{
    width: 100%;
    text-align: center;
    margin-right: 50px;
  }
}
</style>
